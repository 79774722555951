.page-sidebar {
  margin: 50px 0;
  section.widget {
    h4 {
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid $brand-lighter;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
  }
}