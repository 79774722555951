header {
  height: 70px;
  position: absolute;
  width: 100%;
  color: #ffffff;
  transition: all 0.4s ease;

  &.header-fixed {
    position: fixed;
    top:0; left:0;
    width: 100%;
    height: 55px;
    background: #ffffff;
    border-bottom: 1px solid $gray-lighter;
    z-index: 9999;
    .navbar {
      .container {
        .client-link {
          top: 15px;
        }
      }
    }

    .navbar-default {
      .navbar-nav {
        > li {
          > a {
            color: $gray-base;
            padding: 15px 15px;
          }
        }
      }
      .navbar-brand {
        height: 35px;
      }
      .navbar-toggle {
        margin: 9px 0;
        .icon-bar {
          background-color: $gray-base;
        }
      }
    }
    .navbar {
      .container {
        .client-link {
          a {
            color: #000000;
          }
        }
      }
    }
  }

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
    .container {
      position: relative;
      .client-link {
        float: right;
        position: absolute;
        right: 0;
        top: 25px;
        a {
          color: #ffffff;
          text-decoration: none;
          &:hover, &:focus {
            color: $brand-primary;
          }
        }
      }
    }
  }

  #g5-navbar {
    text-align: center;
  }

  $bgDefault: transparent;
  $bgHighlight: transparent;
  $colDefault: #ffffff;
  $colHighlight: $brand-primary;
  .navbar-default {
    font-family: 'Oxygen', sans-serif;
    font-size: 16px;
    background-color: transparent;
    border-color: transparent;
    z-index: 999;
    .navbar-brand {
      color: $colDefault;
      margin: 10px 0;
      padding: 0 15px 0 15px;
      &:hover, &:focus {
        color: $colHighlight;
      }
      img {
        height: 100%;
      }
    }
    .navbar-text {
      color: $colDefault;
    }
    .nav-signin {
      > a {
        color: $colHighlight !important;
      }
    }
    .navbar-nav {
      float: none;
      display: inline-block;
      > li {
        > a {
          color: $colDefault;
          padding: 25px 15px;
          &:hover, &:focus {
            color: $colHighlight;
          }
        }
      }
      > .active {
        > a, > a:hover, > a:focus {
          color: $colHighlight;
          background-color: $bgHighlight;
        }
      }
      > .open {
        > a, > a:hover, > a:focus {
          color: $colHighlight;
          background-color: $bgHighlight;
        }
      }
    }
    .navbar-toggle {
      border-color: $bgHighlight;
      margin: 18px 0;
      &:hover, &:focus {
        background-color: $bgHighlight;
      }
      .icon-bar {
        background-color: $colDefault;
      }
    }
    .navbar-collapse,
    .navbar-form {
      border-color: $colDefault;
    }
    .navbar-link {
      color: $colDefault;
      &:hover {
        color: $colHighlight;
      }
    }
    @media (max-width: 768px) {
      .client-link {
        position: absolute;
        left: 50%;
        max-width:: 100px;;
      }
      .navbar-collapse {
        border-top: 1px solid $gray-lighter;
        border-bottom: 1px solid $gray-lighter;
        &.in {
          background: #ffffff;
          .navbar-nav {
            li {
              a {
                color: $gray-base;
                &:hover, &:focus {
                  color: $colHighlight;
                }
              }
            }
          }
        }
      }
      .collapsing {
        background: #ffffff;
        .navbar-nav {
          li {
            a {
              color: $gray-base;
              &:hover, &:focus {
                color: $colHighlight;
              }
            }
          }
        }
      }
    }

  }

  @media (max-width: 767px) {
    .navbar-default {
      .navbar-nav {
        margin: 0 -15px;
        > li {
          > a {
            padding: 15px 15px;
          }
        }
      }
    }
    .navbar-default .navbar-nav .open {
      > li > a {
        color: $colDefault;
        &:hover, &:focus {
          color: #e7e7e7;
        }
      }
      > .active {
        > a, > a:hover, > a:focus {
          color: $colHighlight;
          background-color: $bgHighlight;
        }
      }
    }
  }
}