.parallax {
  height: 768px;
  background-image: url('../images/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: $brand-primary-darker;
  position: relative;
  display: flex;
  align-items: center;
}

.front-page {
  margin: 65px 0;
  .heading-max {
    font-size: 60px;
  }
  .heading-sub {
    font-size: 28px;
  }
}

.home-services {
  background: $gray-lighter;
  .box {
    padding-top: 60px;
    padding-bottom: 60px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-top: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    font-size: 20px;
    img {
      max-width: 270px;
      height: auto;
      min-height: 200px;
      display: block;
      margin: 20px auto;
    }
    h4 {
      margin-top: 20px;
      font-weight: 300;
      font-size: 26px;
      img {
        width: 30px;
        height: 30px;
        min-height: 30px;
        display: inline-block;
      }
    }
  }
}

.page-content {
  padding: 55px 0 65px 0;
}

.header-banner {
  height: 300px;
  color: #ffffff;
  position: relative;
  .banner-overlay {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 2;
  }
  h1 {
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 40%;
    margin: auto 0;
    z-index: 3;
  }
  .header-banner-image {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, rgba(0,172,255,0.74) 18%, #0074FF 100%);
}
.culture {
  padding: 60px 0;
  h4 {
    font-size: 24px;
  }
  img {
    margin: 15px 0;
    &.star {
      width: 195px;
    }
    &.smile {
      width: 40px;
    }
  }
}