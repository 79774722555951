body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
.hero-text,
.hero-subtext {
  font-family: 'Oxygen', sans-serif;
}

h3 {
  font-weight: 300;
}

span.blue {
  color: $brand-primary;
}

.hero {
  position: relative;
  .hero-text {
    font-size: 48px;
    font-weight: bold;
    font-family: $font-family-monospace;
    //text-transform: uppercase;
    color: #ffffff;
  }
  .hero-subtext {
    font-size: 34px;
    font-weight: bold;
    font-family: $font-family-monospace;
    //text-transform: uppercase;
    color: #ffffff;
  }
  .btn-hero {
    width: 250px;
    height: 50px;
    border-radius: 2px;
    margin-top: 40px;

    span {
      font-size: 24px;
      font-weight: 300;
    }
  }
}
.grow {
  transition: all .2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

