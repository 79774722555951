// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
.wpcf7 {
  label {
    width: 100%;
    input {
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      height: 43px;
      padding: 5px 10px;
    }
    textarea {
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      height: 129px;
      padding: 5px 10px;
      resize: vertical;
    }
    span.wpcf7-not-valid-tip {
      font-weight: 500;
    }
  }
}