footer {
  color: #ffffff;
  h4 {
    color: #ffffff;
  }
  a {
    color: #ffffff;
    &:hover, &:focus {
      color: #ffffff;
      text-decoration: underline;
    }
  }
  .footer-cta {
    min-height: 200px;
    background: rgba(0,0,0,0.35);
    background-position: 100% 80%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .container {
      z-index: 2;
      position: relative;
      .cta-header{
        display: inline-block;
        margin: 66px 0;
        font-family: 'Oxygen', sans-serif;
        font-size: 48px;
        color: #ffffff;
      }
      .cta-button {
        display: inline-block;
        float: right;
        margin: 76px 0;
        .btn-cta {
          width: 250px;
          height: 50px;
          border-radius: 0;
          background-color: transparent;
          border-color: #ffffff;
          border: 3px solid #ffffff;
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          font-size: 24px;
          text-transform: uppercase;
          padding: 6px;
          -webkit-transition: background-color 0.5s;
          -moz-transition: background-color 0.5s;
          -ms-transition: background-color 0.5s;
          -o-transition: background-color 0.5s;
          transition: background-color 0.5s;
          &:hover, &:focus {
            background-color: #1b76bc;
          }
        }
      }
      @media (max-width: 694px) {
        .cta-header {
          margin: 66px 0 33px 0;
          text-align: center;
        }
        .cta-button {
          margin: 38px 0 76px 0;
          float: none;
        }
      }
    }
  }
  .footer-widgets {
    min-height: 300px;
    background: $brand-primary-dark;
    padding: 60px 0;
  }
  .footer-copyright {
    min-height: 50px;
    background: $brand-primary-darker;
    .copyright-text {
      color: $brand-primary;
      font-size: 14px;
      margin: 15px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    .footer-cta {
      .cta-header, .cta-button {
        display: block;
        text-align: center;
        float: none;
      }
    }
  }
}